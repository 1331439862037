














































import { defineComponent, ref, computed } from '@vue/composition-api'
import { honorList } from '../constants/index'

export default defineComponent({
  name: 'DeskHonor',
  setup() {
    const carousel = ref()
    const currentId = ref<number>(1)
    const position = computed(() => honorList.length > 1)
    const sliceGroups = computed(() => honorList.slice(0, 3))

    const handleClick = (index: number) => {
      currentId.value = index
      carousel.value.setActiveItem(index)
    }
    return {
      carousel,
      currentId,
      position,
      sliceGroups,
      honorList,
      handleClick
    }
  }
})
